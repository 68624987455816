<template>
    <div class="orderAttention common-page">
      <my-nav title="工单流转" @to="goBack"></my-nav>
      <div class="group-title color-blue">
        <span>{{ buttonInfo.nodeName }}</span>
        <span class="icon-arrow-blue" v-if="buttonInfo.disposeNodeName"></span>
        <span>{{ buttonInfo.disposeNodeName }}</span>
      </div>
      <van-cell title="催办">
        <template #right-icon>
          <van-switch
            size="30"
            active-color="#3366FE"
            inactive-color="#E9E9EA"
            v-model="form.cuiban"
          />
        </template>
      </van-cell>
      <van-cell
        v-if="form.cuiban"
        :value="form.cbText"
        is-link
        @click="show = true"
        :class="{'check-value': form.cbText!=='请选择'}"
      >
        <template #title>
          <span :class="{ 'cell-title-required': cuibanType.required }"
            >催办类型</span
          >
        </template>
      </van-cell>
      <van-field
        v-if="fieldState.staff.show"
        readonly
        clickable
        :required="fieldState.staff.required"
        name="handler"
        label="处理人"
        :class="['form-input', 'mt-8'].join(' ')"
        right-icon="arrow"
        input-align="right"
        placeholder="请选择"
        @click="handleShowSelectStaff"
        :value="form.handlerName"
      />
      <!-- 工单性质为「品检」的问题工单，在流转（已完成—>已完结）默认加载「问题原因」字段项，下拉选项来源数据字典「问题原因」，见图5，必填； -->
      <van-field
        readonly
        clickable
        @click="issueReasonShow = true"
        name="grade"
        :value="issueReasonValue"
        label="问题原因"
        class="form-input"
        right-icon="arrow"
        input-align="right"
        placeholder="请选择问题原因"
        :required="issueReasonRequired"
        v-if="issueReasonIF"
      />
      <!-- 快捷输入 -->
      <van-field
        readonly
        clickable
        name="picker"
        class="mt-8"
        v-model="quickInput"
        right-icon="arrow"
        label="快捷输入"
        placeholder="点击选择进行快捷输入备注"
        @click="openQuickInput"
      />
      <van-popup v-model="quickInputPickerShow" position="bottom">
        <van-picker
          show-toolbar
          :columns="quickInputColumns"
          @confirm="onQuickInputConfirm"
          @cancel="quickInputPickerShow = false"
        />
      </van-popup>
      <van-field
            name="remark"
            v-model="form.desc"
            label="备注"
            rows="3"
            class="form-textarea mt-8"
            type="textarea"
            placeholder="请输入备注"
         >
        <template v-slot:right-icon v-if="isOffline">
          <micro-text
            @speakRes="speakRes"
          />
        </template>
      </van-field>
      <div class="photoUpload mt-8">
        <common-upload
        :file.sync="form.attachments"
        :enable-watermark="false"
        :enable-album="true"
        :enable-edit="false"
        :oss-params="ossParams"
        :required="fieldState.attachmentsShow.required"
        ></common-upload>
      </div>
      <van-action-sheet v-model="show">
        <div class="content">
          <div class="title">
            <div>催办类型</div>
            <div class="icon-close" @click="show = false"></div>
          </div>
          <div
            class="action-cell"
            @click="chooseAction(item, index)"
            v-for="(item, index) of cuibanList"
            :key="index"
          >
            <div>{{ item.name }}</div>
            <div class="icon-fork" v-show="item.forked"></div>
          </div>
        </div>
      </van-action-sheet>
      <div class="bottom-area">
        <template>
          <van-button :disabled="submitLoading" class="button blue-reverse" @click="cuibanCancel">取消</van-button>
          <van-button :disabled="submitLoading" class="button blue" @click="cuibanSubmit">确定</van-button>
        </template>
      </div>
      <div class="space"></div>

      <!-- 选择处理人 -->
      <select-handler
        v-if="selectHandlerShow"
        :order-detail="form"
        :button-info="buttonInfo"
        :listData="handlerData.roleUserSels"
        v-model="selectHandlerShow"
        @result="setHandler"
      ></select-handler>

    <!-- 问题原因选择器 -->
    <van-popup v-model="issueReasonShow" position="bottom">
      <van-picker
        show-toolbar
        title="选择问题原因"
        :columns="issueReasonList"
        :default-index="0"
        value-key="name"
        @cancel="issueReasonShow = false"
        @confirm="issueReasonSelectConfirm"
      />
    </van-popup>
    </div>
</template>

<script>
import api from '@/api/index'
import moment from 'moment'
import SelectHandler from '@/components/SelectHandlerFlow'
import { setHistoryListDataHandler } from '@/plugins/offlineMixin/offlineApiHandlers/HistoryOrderListOfflineApiHandlers'
import {
  getOssParams
} from '@/plugins/offlineMixin/utils/offlineImgTool'

export default {
  name: 'OrderAttention',
  components: {
    SelectHandler
  },
  data () {
    return {
      quickInput: '',
      quickInputPickerShow: false,
      quickInputColumns: [],
      show: false,
      cuibanList: [], // 催办list
      form: {
        handleEnpoint: 'APP',
        cuiban: false,
        cbText: '请选择', // 催办类型页面显示值
        cbCode: undefined,
        handlerName: undefined,
        handlerId: undefined,
        isBackHandler: undefined,
        desc: '',
        attachments: []
      },
      handlerData: {
        roleUserSels: undefined
      }, // 接口获取到的处理人数据
      selectHandlerShow: false,
      /* 催办类型显示逻辑和必填逻辑 */
      cuibanType: {
        required: true
      },
      ossParams: {}, // 阿里云oss参数
      buttonInfo: {},
      orderDetail: {},
      submitFlag: true, // 最终能否允许用户提交表单的判断标识
      fieldState: {
        /* 原因类型 */
        reasonType: {
          show: false,
          required: false
        },
        // 原因细类
        reasonFineType: {
          show: false,
          required: false
        },
        problemTags: {
          show: false,
          required: false
        },
        staff: {
          show: false,
          required: false
        },
        closeType: {
          show: false,
          required: false
        },
        attachmentsShow: {
          show: true, // 需要展示图片
          required: false
        }
      },
      issueReasonList: [],
      issueReasonShow: false,
      issueReasonCode: null,
      issueReasonIF: false,
      issueReasonRequired: false,
      submitLoading: false
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    issueReasonValue () {
      const issueReasonName = this.issueReasonList.find(item => {
        return item.code === this.issueReasonCode
      })
      return issueReasonName ? issueReasonName.name : null
    }
  },
  async mounted () {
    this.orderDetail = this.$storage.get('orderDetail')
    this.form.orderNo = this.orderDetail.order_no
    this.buttonInfo = this.$storage.get('buttonInfo')
    this.form.buttonName = this.buttonInfo.buttonName
    this.form.curNodeId = this.buttonInfo.nodeId
    this.dictionaryitem()
    this.initIssueReason()
    const getHandlerQuery = {
      orderNo: this.orderDetail.order_no,
      buttonName: undefined,
      bigCategoryCode: this.orderDetail.big_category_code,
      smallCategoryCode: this.orderDetail.small_category_code,
      bigCategoryName: this.orderDetail.big_category_name,
      smallCategoryName: this.orderDetail.small_category_name
    }
    this.getQuickInputColumns()
    this.fieldConfig = this.buttonInfo.fieldConfig ? JSON.parse(this.buttonInfo.fieldConfig) : {}
    this.getfieldShow(this.fieldConfig)

    if (this.fieldState.staff.show) {
      this.getHandlerData(getHandlerQuery, this.buttonInfo)
    }
    this.ossParams = await getOssParams()
  },
  methods: {
    async getQuickInputColumns () {
      if (this.isOffline) {
        const categoryCode = this.orderDetail.small_category_code ? this.orderDetail.small_category_code : this.orderDetail.big_category_code
        console.log('categoryCode: ', categoryCode)
        if (!categoryCode) return
        const result = await this.$http.get(`${api.quickInputListReq}${categoryCode}/${this.orderDetail.template_node_code}1`)
        console.log('getQuickInputColumns result: ', result)
        this.quickInputColumns = result.map(item => item.content)
      }
    },
    onQuickInputConfirm (value) {
      console.log('value: ', value)
      const desc = this.form.desc
      this.form.desc = desc + value
      this.quickInputPickerShow = false
    },
    openQuickInput () {
      if (this.quickInputColumns.length === 0) {
        this.$toast('无快捷输入选项')
      } else {
        this.quickInputPickerShow = true
      }
    },
    goBack () {
      window.history.go(-1)
      // this.$router.back()
    },
    /* 选择催办类型 */
    chooseAction (item, index) {
      this.cuibanList.forEach((item) => {
        item.forked = false
      })
      this.cuibanList[index].forked = true
      this.form.cbCode = this.cuibanList[index].code
      this.form.cbText = this.cuibanList[index].name
      this.show = false
    },
    /* 用户表单数据提交 */
    async cuibanSubmit () {
      this.submitLoading = true
      const loading = this.$toast.loading({
        duration: 0,
        message: '处理中...',
        forbidClick: true
      })
      try {
        this.form.isBackHandler = this.fieldState.staff.show && this.form.handlerId ? 1 : undefined
        const formCopy = this.lodash.cloneDeep(this.form)
        this.fieldState.staff.show ? formCopy.isBackHandler = 1 : formCopy.isBackHandler = undefined
        this.submitFlag = true
        this.beforeSubmit()
        if (!this.submitFlag) {
          this.submitLoading = false
          return
        }
        if (!this.isOffline) {
          this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
          return
        }
        if (this.submitFlag) {
          formCopy.commitTime = moment().format('YYYY-MM-DD HH:mm:ss')
          if (this.issueReasonCode) {
            formCopy.issueReason = this.issueReasonCode
            const issueReason = this.issueReasonList.find(i => i.code === formCopy.issueReason)
            formCopy.issueReasonName = issueReason.name || ''
          }
          console.log('上传前formCopy: ', this.lodash.cloneDeep(formCopy))
          await this.$apiOffline({
            api: api.OrderFlow,
            methods: 'post',
            params: { ...formCopy },
            showLoading: false
          })
          if (this.orderDetail.template_node_code === 'yijiedan' || this.orderDetail.template_node_code === 'chulizhong' || this.orderDetail.template_node_code === 'daifenpai') {
            await this.$apiOffline({
              params: this.orderDetail,
              offlineApiHandlers: setHistoryListDataHandler,
              forceOffline: true
            })
          }
          const toastMessage = formCopy.cuiban ? '工单催办成功' : '跟进成功'
          this.$store.commit('changeDashBoardRefreshMixin')
          this.$toast.success({
            message: toastMessage,
            duration: 1000,
            forbidClick: true,
            onClose: this.goBack
          })
        }
      } catch (error) {
        loading && loading.clear()
        this.submitLoading = false
      }
    },
    /* 提交前预处理 */
    beforeSubmit () {
      if (this.form.cuiban) {
        if (!this.form.cbCode) {
          this.$toast('请选择催办类型')
          this.submitFlag = false
          return false
        } else {
          this.submitFlag = true
        }
      }
      if (this.fieldState.staff.required) {
        if (!(this.form.handlerId && this.form.handlerName)) {
          this.$toast('请选择处理人')
          this.submitFlag = false
          return false
        } else {
          this.submitFlag = true
        }
      }
      if (this.issueReasonRequired && !this.issueReasonCode) {
        this.$toast('请选择问题原因')
        this.submitFlag = true
        return false
      }
      // 图片
      if (this.fieldState.attachmentsShow.required) {
        if (!this.form.attachments || !this.form.attachments.length) {
          this.$toast('请上传图片')
          this.submitFlag = false
          return false
        }
      }
      // 没有处理人的输入框时默认详情的值
      if (!this.fieldState.staff.show) {
        this.handleForm()
      }
    },
    /* 取消催办 */
    cuibanCancel () {
      this.goBack()
    },
    /* 处理人操作 */
    setHandler (item) {
      this.form.handlerName = item.userName
      this.form.handlerId = item.userId
    },
    /* 提前获取处理人 */
    getHandlerData (form, buttonInfo) {
      console.log('handlerForm:', form)
      if (!this.buttonInfo || !this.buttonInfo.fieldConfig) return
      const requestParams = {
        orderNo: form.orderNo,
        // buttonName: buttonInfo.buttonName,
        buttonName: buttonInfo.buttonName,
        bigCategoryCode: form.bigCategoryCode,
        smallCategoryCode: form.smallCategoryCode
      }
      this.$http
        .get(api.gethandlerUsers, requestParams)
        .then((res) => {
          // 如果后端反了用后端的 如果后端没反用预处理人 如果没有预处理人用处理人
          // 2022/06/10 res.filter为true时不过滤 否则走原来的回填
          if (!res) {
            this.handleForm()
            console.log('无后端返回')
            return
          }
          let userInfo = this.$store.getters.getUserInfo
          console.log(1111111111111111)
          console.log(res.filter)
          console.log(userInfo, res.userId.toString(), res)
          console.log(res.roleUserSels)
          console.log(userInfo.id !== 0, userInfo.id === res.userId.toString())
          console.log(1111111111111111)
          // 2022/6/27 filter判断赋值
          if ((userInfo.id !== 0 && userInfo.id === res.userId.toString()) || res.filter) {
            this.form.handlerName = res.userName
            this.form.handlerId = res.userId
          } else {
            ;(res.roleUserSels || []).forEach((sel) => {
              const { userSelList } = sel
              if (userSelList && userSelList.length > 0) {
                const findItem = userSelList.find(item => {
                  return item.userId.toString() === userInfo.id
                })
                if (findItem) {
                  this.form.handlerName = findItem.userName
                  this.form.handlerId = findItem.userId
                  console.log('后端')
                } else {
                  this.handleForm('noId') // 不传id
                }
                // userSelList.forEach(item => {
                //   if (userInfo.id === item.userId.toString()) {
                //     this.form.handlerName = item.userName
                //     this.form.handlerId = item.userId
                //     console.log('后端')
                //   }
                // })
              } else {
                this.handleForm('noId')// 不传id
              }
            })
          }
          this.handlerData = res
        })
    },
    // 预处理人和处理人判断
    handleForm (noid = false) {
      // 匹配预处理人或处理人 以为处理人会有多个人 所以要分割 如果是预处理人就用pre_handlers字段 是处理人就用handler_id字段
      let userInfo = this.$store.getters.getUserInfo
      // 预处理人
      let preId = (this.orderDetail.pre_handlers?.toString() || '').split(',')
      // 处理人
      let handerId = (this.orderDetail.handler_id?.toString() || '').split(',')
      // 名字
      let handerName = (this.orderDetail.handler_name?.toString() || '').split('、')
      console.log(handerName)
      console.log('处理后的字段', preId, handerId, handerName, userInfo)
      if (preId[0] !== '') {
        preId.forEach((i, index) => {
          if (i === userInfo.id) {
            this.form.handlerName = handerName[index]
            if (!noid) { this.form.handlerId = preId[index] }
            console.log('走了预处理人', handerName[index], preId[index])
          } else {
            this.form.handlerName = handerName.join()
          }
        })
      } else if (handerId[0] !== '') {
        handerId.forEach((i, index) => {
          if (i === userInfo.id) {
            this.form.handlerName = handerName[index]
            if (!noid) { this.form.handlerId = handerId[index] }
            console.log('走处理人', handerName[index], handerId[index])
          } else {
            this.form.handlerName = handerName.join()
          }
        })
      }
    },
    /* 获取数据字典 */
    dictionaryitem () {
      this.$http
        .get(api.dictionaryitem, {
          dic_code: ''
        })
        .then((res) => {
          this.cuibanList = res.filter((item) => {
            return item.dicCode === 'cuiban'
          })
          this.cuibanList.forEach((item) => {
            //   item.forked = false
            this.$set(item, 'forked', false)
          })
          // 品检工单选择【问题原因】
          if (this.orderDetail.order_nature === 5) {
            this.issueReasonList = res.filter(
              (item) => item.dicCode === 'issueReason'
            )
          }
        })
    },
    handleShowSelectStaff () {
      if (!this.handlerData.roleUserSels || this.handlerData.roleUserSels.length === 0) {
        return
      }
      this.selectHandlerShow = true
    },
    /* 获取流程页面应该展示的字段,每个字段的校验逻辑，并在页面上进行动态的渲染 */
    getfieldShow (list) {
      if (list.length > 0) {
        list.forEach((item) => {
          if (item.name === 'attachments') {
            this.fieldState.attachmentsShow.required = !!item.ischeck
          }
          if (item.name === 'yuanyindalei') {
            this.fieldState.reasonType.show = true
            if (item.ischeck) {
              this.fieldState.reasonType.required = true
            } else {
              this.fieldState.reasonType.required = false
            }
          }
          if (item.name === 'yuanyinxilei') {
            this.fieldState.reasonFineType.show = true
            if (item.ischeck) {
              this.fieldState.reasonFineType.required = true
              this.fieldState.reasonType.required = true
            } else {
              this.fieldState.reasonFineType.required = false
            }
          }
          if (item.name === 'wentibiaoqian') {
            this.fieldState.problemTags.show = true
            if (item.ischeck) {
              this.fieldState.problemTags.required = true
            } else {
              this.fieldState.problemTags.required = false
            }
          }
          if (item.name === 'chuliren') {
            this.fieldState.staff.show = true
            if (item.ischeck) {
              this.fieldState.staff.required = true
            } else {
              this.fieldState.staff.required = false
            }
          }
          if (item.name === 'guanbileixing') {
            this.fieldState.closeType.show = true
            if (item.ischeck) {
              this.fieldState.closeType.required = true
            } else {
              this.fieldState.closeType.required = false
            }
          }
        })
      } else {
        return false
      }
    },
    speakRes (text) {
      this.form.desc += text
    },
    issueReasonSelectConfirm (issueReasonItem) {
      this.issueReasonCode = issueReasonItem.code
      this.issueReasonShow = false
    },
    // 【问题原因】sprint20221125新增功能
    initIssueReason () {
      let show = false
      let required = false
      if (this.orderDetail.order_nature === 5) {
        // 跟进
        if (this.buttonInfo.buttonType === 1) {
          if (['daifenpai', 'yijiedan', 'chulizhong'].includes(this.buttonInfo.curTemplateNodeCode)) {
            show = true
          }
        } else if (this.buttonInfo.buttonType === 2 && this.buttonInfo.curTemplateNodeCode === 'yiwancheng' && this.buttonInfo.disposeTemplateNodeCode === 'yiwanjie') {
          // 流转（已完成—>已完结）
          show = true
          required = true
          // 工单性质为「品检」的问题工单,如在跟进过程中已提交过问题原因则自动加载并可重新选择
        }
        if (this.orderDetail.issueReason) {
          this.issueReasonCode = this.orderDetail.issueReason
        }
      }
      this.issueReasonIF = show
      this.issueReasonRequired = required
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
