var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderAttention common-page" },
    [
      _c("my-nav", { attrs: { title: "工单流转" }, on: { to: _vm.goBack } }),
      _c("div", { staticClass: "group-title color-blue" }, [
        _c("span", [_vm._v(_vm._s(_vm.buttonInfo.nodeName))]),
        _vm.buttonInfo.disposeNodeName
          ? _c("span", { staticClass: "icon-arrow-blue" })
          : _vm._e(),
        _c("span", [_vm._v(_vm._s(_vm.buttonInfo.disposeNodeName))]),
      ]),
      _c("van-cell", {
        attrs: { title: "催办" },
        scopedSlots: _vm._u([
          {
            key: "right-icon",
            fn: function () {
              return [
                _c("van-switch", {
                  attrs: {
                    size: "30",
                    "active-color": "#3366FE",
                    "inactive-color": "#E9E9EA",
                  },
                  model: {
                    value: _vm.form.cuiban,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cuiban", $$v)
                    },
                    expression: "form.cuiban",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.form.cuiban
        ? _c("van-cell", {
            class: { "check-value": _vm.form.cbText !== "请选择" },
            attrs: { value: _vm.form.cbText, "is-link": "" },
            on: {
              click: function ($event) {
                _vm.show = true
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c(
                        "span",
                        {
                          class: {
                            "cell-title-required": _vm.cuibanType.required,
                          },
                        },
                        [_vm._v("催办类型")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3207195364
            ),
          })
        : _vm._e(),
      _vm.fieldState.staff.show
        ? _c("van-field", {
            class: ["form-input", "mt-8"].join(" "),
            attrs: {
              readonly: "",
              clickable: "",
              required: _vm.fieldState.staff.required,
              name: "handler",
              label: "处理人",
              "right-icon": "arrow",
              "input-align": "right",
              placeholder: "请选择",
              value: _vm.form.handlerName,
            },
            on: { click: _vm.handleShowSelectStaff },
          })
        : _vm._e(),
      _vm.issueReasonIF
        ? _c("van-field", {
            staticClass: "form-input",
            attrs: {
              readonly: "",
              clickable: "",
              name: "grade",
              value: _vm.issueReasonValue,
              label: "问题原因",
              "right-icon": "arrow",
              "input-align": "right",
              placeholder: "请选择问题原因",
              required: _vm.issueReasonRequired,
            },
            on: {
              click: function ($event) {
                _vm.issueReasonShow = true
              },
            },
          })
        : _vm._e(),
      _c("van-field", {
        staticClass: "mt-8",
        attrs: {
          readonly: "",
          clickable: "",
          name: "picker",
          "right-icon": "arrow",
          label: "快捷输入",
          placeholder: "点击选择进行快捷输入备注",
        },
        on: { click: _vm.openQuickInput },
        model: {
          value: _vm.quickInput,
          callback: function ($$v) {
            _vm.quickInput = $$v
          },
          expression: "quickInput",
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.quickInputPickerShow,
            callback: function ($$v) {
              _vm.quickInputPickerShow = $$v
            },
            expression: "quickInputPickerShow",
          },
        },
        [
          _c("van-picker", {
            attrs: { "show-toolbar": "", columns: _vm.quickInputColumns },
            on: {
              confirm: _vm.onQuickInputConfirm,
              cancel: function ($event) {
                _vm.quickInputPickerShow = false
              },
            },
          }),
        ],
        1
      ),
      _c("van-field", {
        staticClass: "form-textarea mt-8",
        attrs: {
          name: "remark",
          label: "备注",
          rows: "3",
          type: "textarea",
          placeholder: "请输入备注",
        },
        scopedSlots: _vm._u(
          [
            _vm.isOffline
              ? {
                  key: "right-icon",
                  fn: function () {
                    return [
                      _c("micro-text", { on: { speakRes: _vm.speakRes } }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
        model: {
          value: _vm.form.desc,
          callback: function ($$v) {
            _vm.$set(_vm.form, "desc", $$v)
          },
          expression: "form.desc",
        },
      }),
      _c(
        "div",
        { staticClass: "photoUpload mt-8" },
        [
          _c("common-upload", {
            attrs: {
              file: _vm.form.attachments,
              "enable-watermark": false,
              "enable-album": true,
              "enable-edit": false,
              "oss-params": _vm.ossParams,
              required: _vm.fieldState.attachmentsShow.required,
            },
            on: {
              "update:file": function ($event) {
                return _vm.$set(_vm.form, "attachments", $event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "van-action-sheet",
        {
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "title" }, [
                _c("div", [_vm._v("催办类型")]),
                _c("div", {
                  staticClass: "icon-close",
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                }),
              ]),
              _vm._l(_vm.cuibanList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "action-cell",
                    on: {
                      click: function ($event) {
                        return _vm.chooseAction(item, index)
                      },
                    },
                  },
                  [
                    _c("div", [_vm._v(_vm._s(item.name))]),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.forked,
                          expression: "item.forked",
                        },
                      ],
                      staticClass: "icon-fork",
                    }),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "bottom-area" },
        [
          [
            _c(
              "van-button",
              {
                staticClass: "button blue-reverse",
                attrs: { disabled: _vm.submitLoading },
                on: { click: _vm.cuibanCancel },
              },
              [_vm._v("取消")]
            ),
            _c(
              "van-button",
              {
                staticClass: "button blue",
                attrs: { disabled: _vm.submitLoading },
                on: { click: _vm.cuibanSubmit },
              },
              [_vm._v("确定")]
            ),
          ],
        ],
        2
      ),
      _c("div", { staticClass: "space" }),
      _vm.selectHandlerShow
        ? _c("select-handler", {
            attrs: {
              "order-detail": _vm.form,
              "button-info": _vm.buttonInfo,
              listData: _vm.handlerData.roleUserSels,
            },
            on: { result: _vm.setHandler },
            model: {
              value: _vm.selectHandlerShow,
              callback: function ($$v) {
                _vm.selectHandlerShow = $$v
              },
              expression: "selectHandlerShow",
            },
          })
        : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.issueReasonShow,
            callback: function ($$v) {
              _vm.issueReasonShow = $$v
            },
            expression: "issueReasonShow",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              title: "选择问题原因",
              columns: _vm.issueReasonList,
              "default-index": 0,
              "value-key": "name",
            },
            on: {
              cancel: function ($event) {
                _vm.issueReasonShow = false
              },
              confirm: _vm.issueReasonSelectConfirm,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }